import React, { useState } from 'react';
import {
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Tab,
  Tabs,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import './InboxPage.css';

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
  width: '100vw',
  padding: 0,
  margin: 0,
  boxSizing: 'border-box',
});

const StyledSidebar = styled(Box)({
  width: '30%',
  borderRight: '1px solid #e0e0e0',
  overflowY: 'auto',
  boxSizing: 'border-box',
  padding: 0,
  margin: 0,
});

const StyledPerson = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  borderBottom: '1px solid #e0e0e0',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f5f5f5'
  }
});

const StyledPersonDetails = styled(Box)({
  marginLeft: '10px',
  flexGrow: 1
});

const StyledMessagesContainer = styled(Box)({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: 0,
  margin: 0,
});

const StyledMessageHeader = styled(Box)({
  backgroundColor: '#ffffff',
  color: '#000000',
  boxShadow: 'none',
  borderBottom: '1px solid #e0e0e0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0,
  margin: 0,
});

const StyledMessageBody = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  padding: '10px',
  direction: 'rtl'
});

const StyledMessageInput = styled(Box)({
  padding: '10px',
  borderTop: '1px solid #e0e0e0',
  display: 'flex',
  alignItems: 'center',
  margin: 0,
});

const StyledTextField = styled(TextField)({
  flexGrow: 1,
  marginLeft: '10px',
  marginRight: '10px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    '& fieldset': {
      borderColor: '#cccccc',
    },
    '&:hover fieldset': {
      borderColor: '#3cbf94',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#cccccc',
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem',
  },
  '& .MuiInputLabel-root': {
    color: '#000000',
    fontWeight: 'bold',
  },
  '& .MuiInputLabel-shrink': {
    color: '#000000 !important',
  },
});

const StyledButton = styled(Button)({
  borderRadius: '20px',
  backgroundColor: '#3cbf94',
  color: '#ffffff',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#3cbf9480',
  }
});

const StyledTabPanel = styled(Box)({
  padding: '10px',
  flex: 1,
  overflowY: 'auto'
});

const StyledMessageBubble = styled(Box)(({ sender }) => ({
  backgroundColor: sender ? '#3cbf94' : '#f1f1f1',
  color: sender ? '#ffffff' : '#000000',
  padding: '10px',
  borderRadius: '15px',
  maxWidth: '60%',
  marginBottom: '10px',
  textAlign: sender ? 'right' : 'left',
  alignSelf: sender ? 'flex-end' : 'flex-start'
}));

const persons = [
  {
    id: 1,
    name: 'جون دو',
    avatar: 'https://via.placeholder.com/40',
    coverImage: 'https://via.placeholder.com/150',
    lastSignIn: '10 يوليو 2024، 10:30 صباحاً',
    info: {
      joinDate: '1 يناير 2020',
      boats: 3,
      surname: 'دو',
      moreInfo: 'معلومات إضافية عن جون دو',
      address: '123 شارع الملك فهد, الرياض, السعودية'
    }
  },
  {
    id: 2,
    name: 'جين سميث',
    avatar: 'https://via.placeholder.com/40',
    coverImage: 'https://via.placeholder.com/150',
    lastSignIn: '8 يوليو 2024، 12:00 مساءً',
    info: {
      joinDate: '15 مارس 2019',
      boats: 2,
      surname: 'سميث',
      moreInfo: 'معلومات إضافية عن جين سميث',
      address: '456 شارع الأمير محمد بن عبدالعزيز, جدة, السعودية'
    }
  }
];

const messages = [
  { id: 1, content: 'مرحباً! كيف حالك؟', time: '10:30 صباحاً', avatar: 'https://via.placeholder.com/40', sender: true },
  { id: 2, content: 'أنا بخير، شكراً!', time: '10:32 صباحاً', avatar: 'https://via.placeholder.com/40', sender: false },
  { id: 3, content: 'هل كل شيء جاهز للرحلة؟', time: '10:35 صباحاً', avatar: 'https://via.placeholder.com/40', sender: true },
  { id: 4, content: 'نعم، كل شيء جاهز. سنراك غداً!', time: '10:37 صباحاً', avatar: 'https://via.placeholder.com/40', sender: false },
];

const Inbox = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [tabValue, setTabValue] = useState(0);
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSendMessage = () => {
    console.log('Send message:', message);
    setMessage('');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHomeClick = () => {
    history.push('/'); // Update the path as per your routing setup
  };

  return (
    <div className="customm-container">
      <StyledContainer>
        <StyledSidebar>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px">
            <Typography variant="h6">الأشخاص</Typography>
            <IconButton onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose} sx={{ fontWeight: '600', fontSize: '12px' }}>مناقشة نشطة</MenuItem>
              <MenuItem onClick={handleMenuClose} sx={{ fontWeight: '600', fontSize: '12px' }}>العروض المفتوحة</MenuItem>
              <MenuItem onClick={handleMenuClose} sx={{ fontWeight: '600', fontSize: '12px' }}>الأرشيف</MenuItem>
              <MenuItem onClick={handleMenuClose} sx={{ fontWeight: '600', fontSize: '12px' }}>الحجوزات المكتملة</MenuItem>
            </Menu>
          </Box>
          {persons.map(person => (
            <Tooltip
              title={
                <Paper style={{ padding: '20px', maxWidth: '300px', borderRadius: '15px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar src={person.avatar} alt="Profile" sx={{ width: 70, height: 70, marginBottom: '10px' }} />
                    <Typography variant="h6" gutterBottom>الاسم: {person.name} {person.info.surname}</Typography>
                    <Typography variant="body2" gutterBottom>تاريخ الانضمام: {person.info.joinDate}</Typography>
                    <Typography variant="body2" gutterBottom>عدد القوارب: {person.info.boats}</Typography>
                    <Typography variant="body2" gutterBottom>العنوان: {person.info.address}</Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#3cbf94', cursor: 'pointer' }}>{person.info.moreInfo}</Typography>
                  </Box>
                </Paper>
              }
              arrow
              placement="left"
              key={person.id}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'common.white',
                    '& .MuiTooltip-arrow': {
                      color: 'common.grey',
                    },
                  },
                },
              }}
            >
              <StyledPerson>
                <Avatar src={person.avatar} alt="Profile" sx={{ borderRadius: '50%' }} />
                <StyledPersonDetails>
                  <Typography variant="body1">{person.name}</Typography>
                  <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'grey' }}>{person.lastSignIn}</Typography>
                </StyledPersonDetails>
              </StyledPerson>
            </Tooltip>
          ))}
        </StyledSidebar>
        <StyledMessagesContainer>
          <StyledMessageHeader position="static">
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Tabs
  value={tabValue}
  onChange={handleTabChange}
  TabIndicatorProps={{ style: { backgroundColor: '#3cbf94' } }}
  sx={{ 
    '& .MuiTab-root': { 
      color: 'black', 
      fontWeight: 'bold' 
    },
    '& .Mui-selected': {
      color: 'black' // Ensures that the selected tab also has black text
    }
  }}
>
  <Tab label="تفاصيل الرحلة" />
  <Tab label="الرسائل" />
</Tabs>

              <Box sx={{ flexGrow: 1 }} />
              <IconButton onClick={handleHomeClick}>
                <CloseIcon />
              </IconButton>
            </Box>
          </StyledMessageHeader>
          {tabValue === 0 && (
            <StyledTabPanel>
              <Typography variant="body1">تفاصيل الرحلة</Typography>
            </StyledTabPanel>
          )}
          {tabValue === 1 && (
            <StyledTabPanel>
              {messages.map((msg) => (
                <Box
                  key={msg.id}
                  display="flex"
                  alignItems="flex-start"
                  marginBottom="10px"
                  style={{ direction: 'rtl' }}
                  sx={{
                    flexDirection: msg.sender ? 'row-reverse' : 'row',
                    textAlign: msg.sender ? 'right' : 'left',
                    marginLeft: msg.sender ? '0' : '10px',
                    marginRight: msg.sender ? '10px' : '0'
                  }}
                >
                  <Avatar src={msg.avatar} alt="Profile" sx={{ borderRadius: '50%', margin: '0 10px' }} />
                  <StyledMessageBubble sender={msg.sender}>
                    <Typography variant="body2">{msg.content}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'grey', fontSize: '10px' }}>{msg.time}</Typography>
                  </StyledMessageBubble>
                </Box>
              ))}
            </StyledTabPanel>
          )}
          <StyledMessageInput>
            <StyledTextField
              variant="outlined"
              placeholder="اكتب رسالة..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <StyledButton onClick={handleSendMessage}>إرسال</StyledButton>
          </StyledMessageInput>
        </StyledMessagesContainer>
      </StyledContainer>
    </div>
  );
};

export default Inbox;
