import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const SectionContainer = styled('section')({
  width: '100%',
  backgroundColor: '#f5f1eb',
  padding: '40px 16px',
  display: 'flex',
  justifyContent: 'center',
});

const ContentContainer = styled(Container)({
  maxWidth: '900px', // Reduced the width of the container
  backgroundColor: '#fff',
  borderRadius: '30px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  padding: '30px',
  flexDirection: 'row',
  alignItems: 'center',
});

const ImageContainer = styled(Box)({
  flex: '1',
  paddingRight: '20px',
  display: 'flex',
  justifyContent: 'center',
});

const TextContainer = styled(Box)({
  flex: '1',
  paddingLeft: '20px',
});

const CustomButton = styled(Button)({
  backgroundColor: '#2a8675',
  color: '#fff',
  marginTop: '20px',
  marginLeft: '10px', // Adjusted for RTL
  padding: '10px 20px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#1d6b5b',
  },
});

const GrowBusinessSection = () => {
  return (
    <SectionContainer>
      <ContentContainer>
        <ImageContainer>
          <img 
            src="https://images.unsplash.com/photo-1471188520444-a325e413e595?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Boating Business" 
            style={{ maxWidth: '100%', borderRadius: '20px' }} 
          />
        </ImageContainer>
        <TextContainer>
          <Typography variant="h4" gutterBottom>
            لننمي أعمالك البحرية معاً
          </Typography>
          <Typography variant="body1" gutterBottom>
            احصل على المزيد من العملاء، واربح المزيد من الأموال، وادفع أقل الرسوم في السوق البحرية الرائدة على مستوى العالم.
          </Typography>
          <Box>
            <CustomButton variant="contained">
              ابدأ اليوم
            </CustomButton>
            <CustomButton variant="outlined" style={{ backgroundColor: 'transparent', color: '#2a8675', borderColor: '#2a8675' }}>
              تعلم المزيد
            </CustomButton>
          </Box>
        </TextContainer>
      </ContentContainer>
    </SectionContainer>
  );
};

export default GrowBusinessSection;
