import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Rating,
  IconButton,
  Button,
  useMediaQuery,
  Divider,
} from "@mui/material";
import {
  Favorite,
  FavoriteBorder,
  Room,
  Close,
  ChevronRight,
  ChevronLeft,
  AccessTime,
  WbSunny,
  DirectionsBoat,
} from "@mui/icons-material";
import Navbar from "../../components/Navbar/Navbar";
import MobileNavbar from "../../components/Navbar/MobileNavbar";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ListingDetails.css";
import axios from "axios";
import config from "../../config/config"; // Import the config file


const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const UserReviewWidget = ({ reviews }) => (
  <Box className="user-review-widget">
    {reviews.map((review, index) => {
      // Get the first letter of the user's name
      const firstLetter = review.user.name.charAt(0).toUpperCase();
      const randomColor = getRandomColor();

      return (
        <Box key={index} className="user-review">
          <Box className="user-info">
            <Box
              className="user-avatar"
              sx={{
                width: 50,
                height: 50,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: randomColor,
                color: '#fff',
                fontSize: 24,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {firstLetter}
            </Box>
            <Box className="user-details" ml={2}>
              <Typography className="user-name">{review.user.name}</Typography>
              <Typography className="user-join-date">
                {review.user.createdAt}
              </Typography>
            </Box>
          </Box>
          <Typography className="user-review-content">
            {review.review_content}
          </Typography>
        </Box>
      );
    })}
  </Box>
);


const userReviews = [
  {
    userName: "محمد علي",
    joinDate: "يناير 2022",
    userImage: "/path/to/user1.jpg",
    content:
      "تجربة رائعة حقًا! كان القارب نظيفًا ومرتبًا وكان الكابتن محترفًا للغاية.",
  },
  {
    userName: "فاطمة الزهراء",
    joinDate: "مارس 2021",
    userImage: "/path/to/user2.jpg",
    content: "كانت الرحلة مذهلة، مناظر رائعة والكابتن كان ودودًا للغاية.",
  },
];

const accommodations = [
  "واي فاي مجاني",
  "موقف سيارات",
  "إفطار مجاني",
  "مسبح",
  "خدمة الغرف",
  "مركز لياقة بدنية",
  "مطعم",
  "بار",
  "مرافق للشواء",
];

const infoBoxesData = [
  {
    title: "الكابتن رتبت بشكل منفصل",
    description: "مطلوب كابتن محترف، مرتب ودفع ثمنها بشكل منفصل",
  },
  {
    title: "الإبحار",
    description: "استمتع بالإبحار في البحر الأزرق العميق مع أفضل التجهيزات.",
  },
  {
    title: "سعة",
    description:
      "القارب يتسع لـ 10 أشخاص كحد أقصى، مما يجعله مثاليًا للمجموعات الكبيرة.",
  },
];


const reviewCriteria = [
  { label: "المسار والتجربة", value: 4 },
  { label: "القيمة", value: 3 },
  { label: "التواصل", value: 5 },
  { label: "السفينة والمعدات", value: 4 },
  { label: "المغادرة والعودة", value: 3 },
  { label: "دقة القائمة", value: 5 },
];

const mapContainerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "24px",
  border: "1px solid grey",
};

const center = {
  lat: 24.7136,
  lng: 46.6753,
};

const dummyListings = [
  {
    id: 1,
    title: "قائمة 1",
    description: "وصف القائمة 1. هذا وصف أطول لاختبار الطول الأقصى.",
    images: [
      "https://plus.unsplash.com/premium_photo-1719262671718-76ace3783857?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1452784444945-3f422708fe5e?q=80&w=1472&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1519313905255-a21c04c3f7da?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1473682150760-51d4f94b09d4?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1533572221885-c9d0f46f7e99?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    ],
    position: "الرياض السعودية",
    reviews: 4,
    priceRange: "50 - 120",
    guests: 5,
    reviewCount: 25,
    bookingCount: 10,
    availability: {
      available: [2, 3, 6, 10, 12, 15, 17, 22, 24, 28],
      unavailable: [
        1, 4, 5, 7, 8, 11, 13, 16, 18, 19, 21, 23, 25, 26, 27, 29, 30, 31,
      ],
    },
  },
];
const ListingDetails = () => {
  const { id } = useParams();
  console.log("معرف القائمة:", id);

  const listing = dummyListings[0];

  const [isFavorite, setIsFavorite] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [listingFromApi, setListing] = useState(null);

  const [status, setStatus] = useState("");
  const [showGallery, setShowGallery] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/listings/${id}`) // Use the dynamic `id` variable
      .then((response) => {
        console.log("Listing Data:", response.data); 
        setListing(response.data);
      })
      .catch((error) => {
        console.error("Error fetching listing data:", error);
      });
  }, [id]); 
  
  
 
  const displayedImages = listingFromApi && listingFromApi.Images
  ? showAllImages
    ? listingFromApi.Images.map((image) => `${config.imageBaseUrl}/${image.url}`)
    : listingFromApi.Images.slice(0, 5).map((image) => `${config.imageBaseUrl}/${image.url}`)
  : dummyListings[0].images;




  const images = listingFromApi && listingFromApi.Images
  ? listingFromApi.Images.map((image) => ({
      original: `${config.imageBaseUrl}/${image.url}`,
      thumbnail: `${config.imageBaseUrl}/${image.url}`,
    }))
  : dummyListings[0].images.map((image) => ({
      original: image,
      thumbnail: image,
    }));



  useEffect(() => {
    if (showGallery) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showGallery]);

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const handleDayClick = (day, isAvailable) => {
    setSelectedDate(day);
    setStatus(isAvailable ? "متاح" : "غير متاح");
  };

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowGallery(true);
  };

  const handleGalleryClose = () => {
    setShowGallery(false);
  };

  const handleInquiryClick = () => {
    history.push("/inquiry-form");
  };

  const isMobile = useMediaQuery("(max-width:768px)");


  return (
    <div>
      {isMobile ? <MobileNavbar /> : <Navbar />}
      <Container maxWidth="xl" sx={{ padding: "20px" }}>
        <Button
          variant="contained"
          className="show-more-button"
          onClick={() => setShowAllImages(!showAllImages)}
        >
          {showAllImages ? "عرض أقل" : "عرض المزيد من الصور"}
        </Button>

        <Box className="image-grid">
          {displayedImages.map((image, index) => (
            <div
              key={index}
              className={`grid-image-wrapper grid-image-${index + 1}`}
            >
              <img
                src={image}
                alt={listing.title}
                className="grid-image"
                onClick={() => handleImageClick(index)}
              />
            </div>
          ))}
          <IconButton
            onClick={toggleFavorite}
            color="primary"
            className="favorite-icon"
          >
            {isFavorite ? <Favorite /> : <FavoriteBorder />}
          </IconButton>
        </Box>

        {showGallery && (
          <Box className="overlay" onClick={handleGalleryClose}>
            <Box
              className="overlay-content"
              onClick={(e) => e.stopPropagation()}
            >
              <IconButton
                className="overlay-close"
                onClick={handleGalleryClose}
              >
                <Close />
              </IconButton>
              <ImageGallery
                items={images}
                startIndex={currentImageIndex}
                showThumbnails={false}
                showFullscreenButton={false}
                showPlayButton={false}
              />
            </Box>
          </Box>
        )}

        <Container maxWidth="xl" className="info-container">
          <Typography className="title">  {listingFromApi?.title || "Title not available"}          </Typography>
          <Box className="stats">
            <Rating
              value={listing.reviews}
              readOnly
              sx={{
                "& .MuiRating-iconFilled": { color: "#f9d978" },
                "& .MuiRating-iconHover": { color: "#f9d978" },
              }}
            />{" "}
            <Typography className="dot">·</Typography>
            <Typography className="reviews">
              ({listing.reviewCount} تقييمات)
            </Typography>
            <Typography className="dot">·</Typography>
            <Typography className="stat-item">
              {listing.bookingCount} حجوزات
            </Typography>
            <Typography className="dot">·</Typography>
            <Typography className="stat-item-location">
              {listing.position}
            </Typography>
          </Box>{" "}
          <Divider className="devider" />
        </Container>

        <Container maxWidth="xl" className="informations-listing">
          <Box className="info-box">
            <Typography className="stat-item">
              {listingFromApi?.description} حجوزات
            </Typography>
            
            <Divider className="devider" />
            <Box className="custom-date-picker">
              <CustomCalendar
                dates={listing.availability}
                monthOffset={0}
                onDayClick={handleDayClick}
              />
              <CustomCalendar
                dates={listing.availability}
                monthOffset={1}
                onDayClick={handleDayClick}
              />
            </Box>

            <Box
              className={`avaibility-box ${
                status === "متاح" ? "available" : "unavailable"
              }`}
            >
              {selectedDate && (
                <Typography className="status">
                  <img
                    src={`/${
                      status === "متاح" ? "available.png" : "unavailable.png"
                    }`}
                    alt={status === "متاح" ? "متاح" : "غير متاح"}
                    className="status-image"
                  />
                  التاريخ: {selectedDate} - الحالة:{" "}
                  {status === "متاح" ? "متاح" : "غير متاح"}
                </Typography>
              )}
            </Box>

            <Divider className="devider" />
            <Typography className="status">التقييمات</Typography>
            {listingFromApi && listingFromApi.averageRatings && (
  <ReviewWidget averageRatings={listingFromApi.averageRatings} />
)}
            <Divider className="devider" />
            <UserReviewWidget reviews={listingFromApi?.reviews || []} />
            <Divider className="devider" />
            <Typography className="status">ما يقدمه هذا</Typography>

            {listingFromApi?.Features && <FeaturesWidget features={listingFromApi.Features} />}
            <Divider className="devider" />

            <Box className="custom-info-box-container">
              {listingFromApi?.Benefits.map((info, index) => (
                <Box key={index} className="custom-info-box">
                  <Box className="custom-info-text">
                    <Typography className="custom-info-title">
                      {info?.name}
                    </Typography>
                    <Typography className="custom-info-description">
                      {info?.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Divider className="devider" />
            <Box className="map-wrapper">
              <LoadScript googleMapsApiKey="AIzaSyBM5IqK_gGz6O2WyABZjVJT21lUHTfNM84">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  zoom={13}
                >
                  <Marker position={center} />
                </GoogleMap>
              </LoadScript>
            </Box>
          </Box>

          <Box className="booking-box">
          <Typography className="price">
  تبدأ من {listingFromApi?.priceRange?.min || 0} إلى {listingFromApi?.priceRange?.max || 0} ريال سعودي
</Typography>

            <button className="inquire-button" onClick={handleInquiryClick}>
              إرسال استفسار
            </button>
            <Box className="price-box">
              
              <Box className="column-max">
                <Box className="day-counts-col">
                  <Typography className="day-counts">
                    عدد الأيام الأدنى
                  </Typography>
                  <Typography className="price-text">1 يوم كحد أدنى</Typography>
                  <Divider className="devider" />
                  <Typography className="price-text">
                    7 أيام كحد أدنى
                  </Typography>
                </Box>
                <Box className="day-counts-col">
                  <Typography className="max">التكلفة اليومية</Typography>
                  <Typography className="price-text">
                    757 ريال سعودي/اليوم
                  </Typography>
                  <Divider className="devider" />
                  <Typography className="price-text">
                    654 ريال سعودي/اليوم
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Container>
    </div>
  );
};

const monthNames = [
  "يناير",
  "فبراير",
  "مارس",
  "أبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

const CustomCalendar = ({ dates, onDayClick }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const getDaysInMonth = (month, year) =>
    new Date(year, month + 1, 0).getDate();

  const changeMonth = (direction) => {
    setCurrentDate(
      new Date(currentDate.setMonth(currentDate.getMonth() + direction))
    );
  };

  const daysInMonth = getDaysInMonth(
    currentDate.getMonth(),
    currentDate.getFullYear()
  );
  const startDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay();
  const monthName = monthNames[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  const daysArray = [];
  for (let i = 0; i < startDay; i++) daysArray.push(null); // Empty days for alignment
  for (let i = 1; i <= daysInMonth; i++) daysArray.push(i);

  return (
    <Box className="calendar">
      <Box className="calendar-navigation">
        <IconButton onClick={() => changeMonth(1)}>
          <ChevronRight />
        </IconButton>
        <Typography
          variant="h6"
          className="month-grid"
        >{`${monthName} ${year}`}</Typography>
        <IconButton onClick={() => changeMonth(-1)}>
          <ChevronLeft />
        </IconButton>
      </Box>
      <Box className="calendar-grid">
        {[
          "الأحد",
          "الإثنين",
          "الثلاثاء",
          "الأربعاء",
          "الخميس",
          "الجمعة",
          "السبت",
        ].map((day) => (
          <Box key={day} className="calendar-day-header">
            {day}
          </Box>
        ))}
        {daysArray.map((day, index) => {
          if (!day)
            return <Box key={index} className="calendar-day empty"></Box>;

          const isAvailable = dates.available.includes(day);
          const isUnavailable = dates.unavailable.includes(day);

          return (
            <Box
              key={index}
              className={`calendar-day ${
                isAvailable ? "available" : isUnavailable ? "unavailable" : ""
              }`}
              onClick={() => onDayClick(day, isAvailable)}
            >
              {day}
              {isAvailable && (
                <span className="status-point available-point"></span>
              )}
              {isUnavailable && (
                <span className="status-point unavailable-point"></span>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};



const FeaturesWidget = ({ features }) => {
  const halfIndex = Math.ceil(features.length / 2);
  const firstColumn = features.slice(0, halfIndex);
  const secondColumn = features.slice(halfIndex);

  return (
    <Box className="features-widget">
      <Box className="features-column">
        {firstColumn.map((feature, index) => (
          <Box 
            key={index} 
            className="feature-item" 
            sx={{ marginTop: 2, marginBottom: 2 }}  // Adds margin to top and bottom
          >
            <img
              src={`${config.imageBaseUrl}/${feature.image}`}
              alt={feature.name}
              className="feature-image"
            />
            <Typography className="feature-text">{feature.name}</Typography>
          </Box>
        ))}
      </Box>
      <Box className="features-column">
        {secondColumn.map((feature, index) => (
          <Box 
            key={index} 
            className="feature-item" 
            sx={{ marginTop: 2, marginBottom: 2 }}  // Adds margin to top and bottom
          >
            <img
              src={`${config.imageBaseUrl}/${feature.image}`}
              alt={feature.name}
              className="feature-image"
            />
            <Typography className="feature-text">{feature.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};


const ReviewWidget = ({ averageRatings }) => {
  const criteria = [
    { label: "المسار والتجربة", value: averageRatings.route_experience },
    { label: "القيمة", value: averageRatings.value },
    { label: "التواصل", value: averageRatings.communication },
    { label: "السفينة والمعدات", value: averageRatings.vessel_equipment },
    { label: "المغادرة والعودة", value: averageRatings.departure_return },
    { label: "دقة القائمة", value: averageRatings.listing_accuracy },
  ];

  const halfIndex = Math.ceil(criteria.length / 2);
  const firstColumn = criteria.slice(0, halfIndex);
  const secondColumn = criteria.slice(halfIndex);

  return (
    <Box className="review-widget">
      <Box className="review-column">
        {firstColumn.map((item, index) => (
          <Box key={index} className="review-item">
            <Typography className="review-label">{item.label}</Typography>
            <Rating
              value={item.value}
              readOnly
              sx={{
                "& .MuiRating-iconFilled": { color: "#f9d978" },
                "& .MuiRating-iconHover": { color: "#f9d978" },
              }}
            />
          </Box>
        ))}
      </Box>
      <Box className="review-column">
        {secondColumn.map((item, index) => (
          <Box key={index} className="review-item">
            <Typography className="review-label">{item.label}</Typography>
            <Rating
              value={item.value}
              readOnly
              sx={{
                "& .MuiRating-iconFilled": { color: "#f9d978" },
                "& .MuiRating-iconHover": { color: "#f9d978" },
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};


export default ListingDetails;
