import React from "react";
import Slider from "react-slick";
import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIcon from '@mui/icons-material/ArrowBackIosNew';

const boatTypes = [
  {
    image:
      "https://getmyboat-user-images1.imgix.net/production/static-images/stickers/sailboat.png?auto=format&fit=max&w=391",
    title: "اليخت الفاخر",
    description: "استمتع برحلة فاخرة على متن هذا اليخت الرائع.",
  },
  {
    image:
      "https://getmyboat-user-images1.imgix.net/production/static-images/stickers/pontoon.png?auto=format&fit=max&w=956",
    title: "قارب الصيد",
    description: "قارب مثالي لرحلات الصيد مع الأصدقاء والعائلة.",
  },
  {
    image:
      "https://getmyboat-user-images1.imgix.net/production/static-images/stickers/yacht.png?auto=format&fit=max&w=1130",
    title: "القارب السريع",
    description: "تجربة السرعة والمغامرة على الماء مع هذا القارب السريع.",
  },
  {
    image:
      "https://www.tessllc.us/wp-content/uploads/2020/07/yacht-post-825x510.jpg",
    title: "قارب الإبحار",
    description: "استمتع بالإبحار الهادئ والاسترخاء على هذا القارب.",
  },
];

const FullWidthContainer = styled("section")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: "16px",
  boxSizing: "border-box",
  backgroundColor: "#f5f1eb",
  direction: "rtl", // Set the overall direction to RTL
});

const SpecificWidthContainer = styled(Container)({
  maxWidth: "1200px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: "800px",
  margin: theme.spacing(4),
  borderRadius: "20px",
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#fff",
}));

const CarouselCard = styled(Card)(({ theme }) => ({
  maxWidth: "250px",
  height: "300px",
  margin: "0 8px",
  borderRadius: "20px",
  boxShadow: "0px 0px 8px #E6E6E6",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "#f4f4f8",
}));

const CustomArrow = styled(IconButton)(({ theme }) => ({
  background: "#2a8675",
  color: "#fff",
  borderRadius: "50%",
  width: "45px",
  height: "44px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  zIndex: 1,
  "&:hover": {
    background: "#18504a",
  },
}));


const CircleContainer = styled(Box)(({ theme }) => ({
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: '1px solid grey',
    display: 'flex',
    alignItems: 'center',
   
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
    
      transform: 'scale(1.1)',
    },
  }));

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const About = () => {
  const sliderRef = React.useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <FullWidthContainer>
      <SpecificWidthContainer>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ paddingTop: 5, color: "#000" }}
        >
          مغامرات بجميع أنواعها
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2,
            mb: 2,
            width: "90%",
            px: 2,
          }}
        >
          <CustomArrow onClick={handlePrev}>
            &#8249;
          </CustomArrow>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ color: "#000", mx: 0.5 }}
          ></Typography>
          <CustomArrow onClick={handleNext}>&#8250;</CustomArrow>
        </Box>
        <Box sx={{ width: "90%", mt: 2 }}>
          <Slider ref={sliderRef} {...settings}>
            {boatTypes.map((boat, index) => (
              <CarouselCard key={index}>
                <CardMedia
                  component="img"
                  height="150"
                  width="100%"
                  image={boat.image}
                  alt={boat.title}
                  sx={{
                    objectFit: "contain",
                  }}
                />
                <CardContent sx={{ textAlign: "left" }}> {/* Align text to right */}
                  <Typography gutterBottom variant="h5" component="div">
                    {boat.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {boat.description}
                  </Typography>
                  
                    
                    <CircleContainer>
                      <ArrowForwardIcon sx={{ fontSize: 15 }}/>
                    </CircleContainer>
                 
                </CardContent>
              </CarouselCard>
            ))}
          </Slider>
        </Box>
      </SpecificWidthContainer>
    </FullWidthContainer>
  );
};

export default About;
