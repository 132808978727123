import React from 'react';
import Slider from 'react-slick';
import { Box, Card, CardContent, Typography, Container, IconButton, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';

const reviews = [
  { 
    image: 'https://www.tessllc.us/wp-content/uploads/2020/07/yacht-post-825x510.jpg', 
    title: 'القارب الفاخر', 
    description: 'استمتع برحلة فاخرة على متن هذا القارب.', 
    rating: 4.5, 
    avatar: 'أ', 
    username: 'عبدالله' 
  },
  { 
    image: 'https://images.unsplash.com/photo-1542351379-ec2b4cdc6f1d?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', 
    title: 'قارب الصيد', 
    description: 'قارب مثالي لرحلات الصيد مع الأصدقاء والعائلة.', 
    rating: 4.0, 
    avatar: 'ب', 
    username: 'محمد' 
  },
  { 
    image: 'https://plus.unsplash.com/premium_photo-1677535536180-c7c9d83f48a8?q=80&w=1633&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', 
    title: 'القارب السريع', 
    description: 'تجربة السرعة والمغامرة على الماء مع هذا القارب السريع.', 
    rating: 3.5, 
    avatar: 'ج', 
    username: 'سامي' 
  },
];

const BackgroundContainer = styled('section')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
  boxSizing: 'border-box',
  position: 'relative',
  backgroundImage: 'url("https://images.unsplash.com/photo-1515855334181-bb9b4d4c1d8a?q=80&w=1489&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed', // Enable parallax effect
  direction: 'rtl', // Set the overall direction to RTL
  minHeight: '100vh',
  overflow: 'hidden',
});

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black overlay with opacity
  zIndex: 1,
});

const ContentWrapper = styled(Container)({
  position: 'relative',
  zIndex: 2, // Ensures the content is above the overlay
  maxWidth: '1200px', // Specific max width
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#fff',
});

const ReviewContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4),
  height: 250,
  
}));

const ReviewCard = styled(Card)(({ theme }) => ({
  maxWidth: '350px',
  margin: '0 8px',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transform: `rotate(${Math.random() * 6 - 3}deg)`,
}));

const CustomButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#2a8675',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 8px',
  '&:hover': {
    backgroundColor: '#1d6b5b',
  },
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Reviews = () => {
  const sliderRef = React.useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <BackgroundContainer>
      <Overlay />
      <ContentWrapper>
        <Typography variant="h4" align="center" gutterBottom sx={{ paddingTop: 5 }}>
          آراء العملاء
        </Typography>
        <ReviewContainer>
          <Slider ref={sliderRef} {...settings}>
            {reviews.map((review, index) => (
              <ReviewCard key={index}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mr: 2 }}>
                    <Avatar>{review.avatar}</Avatar>
                    <Box sx={{ mr: 2, textAlign: 'right' }}> {/* Set text alignment to right */}
                      <Typography variant="h6">{review.username}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {review.title}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {Array.from({ length: Math.floor(review.rating) }).map((_, i) => (
                          <StarIcon key={i} sx={{ color: 'orange' }} />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'right' }}>
                    {review.description}
                  </Typography>
                </CardContent>
              </ReviewCard>
            ))}
          </Slider>
        </ReviewContainer>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CustomButton onClick={handlePrev}>
            <ArrowBackIosIcon />
          </CustomButton>
          <CustomButton onClick={handleNext}>
            <ArrowForwardIosIcon />
          </CustomButton>
        </Box>
      </ContentWrapper>
    </BackgroundContainer>
  );
};

export default Reviews;
