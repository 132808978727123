import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, MenuItem, Popover } from '@mui/material';
import { Menu as MenuIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLanguage } from '../../services/LanguageContext';
import translations from '../../Lang/translations';
import './Navbar.css';

const MobileNavbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const { language, changeLanguage } = useLanguage();
  const t = translations[language];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCreateAccount = () => {
    history.push('/register');
    handleClose();
  };

  const handleSignIn = () => {
    history.push('/signin');
    handleClose();
  };

  const handleLogoClick = () => {
    history.push('/');
  };

  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: 'white', boxShadow: 'none', borderBottom: '1px solid #e0e0e0' }}>
      <Toolbar className="navbar-toolbar">
        {/* Wrap the Typography with IconButton and set onClick */}
        <IconButton edge="start" color="inherit" aria-label="logo" onClick={handleLogoClick}>
          <Typography variant="h6" className="navbar-logo">
            Marisa
          </Typography>
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleClick}>
            <MenuIcon style={{ color: 'black', fontSize: '30px' }} />
          </IconButton>
          <IconButton>
            <AccountCircleIcon style={{ color: 'black', fontSize: '30px' }} />
          </IconButton>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: {
              p: 2,
              width: 200,
              borderRadius: '20px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }
          }}
        >
          <MenuItem onClick={handleClose}>{t.createListing}</MenuItem>
          <MenuItem onClick={handleClose}>{t.help}</MenuItem>
          <MenuItem onClick={handleCreateAccount}>{t.createAccount}</MenuItem>
          <MenuItem onClick={handleSignIn}>{t.signIn}</MenuItem>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

export default MobileNavbar;
