import React from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Home from './pages/Home/Home';
import Register from './pages/Register/Register';
import SignIn from './pages/SignIn/SignIn';
import ListingDetails from './pages/ListingDetails/ListingDetails';
import CreateListing from './pages/CreateListing/CreateListing';
import InquiryForm from './pages/InquiryForm/InquiryForm';
import Profile from './pages/UserProfile/UserProfile';
import InboxPage from './pages/InboxPage/InboxPage';
import About from './pages/AboutUs/AboutUs';
import './App.css';
import { LanguageProvider } from './services/LanguageContext';
import { ThemeProvider } from '@mui/material/styles';
import RTL from './RTL';
import theme from './theme';
import ProtectedRoute from './components/ProtectedRoute'; // Correct import path
import { useAuth } from './services/AuthContext';

const pageVariants = {
  initial: {
    opacity: 0,
    x: '-100vw',
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: '100vw',
  },
};

const pageTransition = {
  type: 'tween',
  duration: 0.5,
};

const MotionRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <Component {...props} />
        </motion.div>
      )}
    />
  );
};

const AnimatedSwitch = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Switch location={location} key={location.pathname}>
        <MotionRoute exact path="/" component={Home} />
        <MotionRoute path="/register" component={Register} />
        <MotionRoute path="/signin" component={SignIn} />
        <MotionRoute path="/listing/:id" component={ListingDetails} />
        <ProtectedRoute path="/create/listing" component={CreateListing} /> {/* Protected Route */}
        <ProtectedRoute path="/inquiry-form" component={InquiryForm} /> {/* Protected Route */}
        <ProtectedRoute path="/profile" component={Profile} /> {/* Protected Route */}
        <ProtectedRoute path="/inbox" component={InboxPage} /> {/* Protected Route */}
        <MotionRoute path="/about" component={About} />
      </Switch>
    </AnimatePresence>
  );
};

const App = () => {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Optional: Loading spinner or placeholder
  }

  return (
    <LanguageProvider>
      <RTL>
        <ThemeProvider theme={theme}>
          <Router>
            <div className="app-container">
              <AnimatedSwitch />
            </div>
          </Router>
        </ThemeProvider>
      </RTL>
    </LanguageProvider>
  );
};

export default App;
