// src/components/ProtectedRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../services/AuthContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { authState, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Optional: Loading spinner or some placeholder
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authState.token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

export default ProtectedRoute;
