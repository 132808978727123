import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import StepConnector from '@mui/material/StepConnector';
import './InquiryForm.css';

// Define the custom StepIcon
const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: ownerState.active ? '#3cbf94' : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.completed && {
    color: '#3cbf94',
  }),
  '& .CustomStepIcon-completedIcon': {
    color: '#3cbf94',
    zIndex: 1,
    fontSize: 18,
  },
  '& .CustomStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <Check className="CustomStepIcon-completedIcon" />
      ) : (
        <div className="CustomStepIcon-circle" />
      )}
    </CustomStepIconRoot>
  );
}

// Define the custom StepLabel
const CustomStepLabel = styled(StepLabel)({
  '& .MuiStepLabel-label.Mui-active': {
    color: '#3cbf94 !important',
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: '#3cbf94 !important',
  },
});

const steps = ['الخطوة 1', 'الخطوة 2', 'الخطوة 3', 'الخطوة 4', 'الخطوة 5', 'الخطوة 6'];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InquiryForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [duration, setDuration] = useState({ days: 0, hours: 0, minutes: 0 });
  const [tripType, setTripType] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [preferredDate, setPreferredDate] = useState(null);
  const [departureTime, setDepartureTime] = useState('');
  const [groupSize, setGroupSize] = useState({ adults: 0, seniors: 0, children: 0, infants: 0 });
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [contactDetails, setContactDetails] = useState({ name: '', email: '', phone: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const history = useHistory();

  const handleNext = () => {
    const totalPeople = groupSize.adults + groupSize.seniors + groupSize.children + groupSize.infants;
    if (
      (activeStep === 0 &&
      ((tripType === 'same-day' && (duration.hours === 0 && duration.minutes === 0)) ||
        (tripType === 'overnight' && duration.days === 0))) ||
      (activeStep === 1 && !preferredDate) ||
      (activeStep === 2 && (!departureTime || totalPeople < 1)) ||
      (activeStep === 3 && additionalInfo.trim() === '') ||
      (activeStep === 4 && (!contactDetails.name || !contactDetails.email || !contactDetails.phone))
    ) {
      setValidationMessage('يجب ملء جميع الحقول المطلوبة.');
      setOpenSnackbar(true);
    } else {
      setValidationMessage('');
      if (activeStep < steps.length - 1) {
        setActiveStep((prevStep) => prevStep + 1);
      } else {
        setOpenDialog(true);
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const incrementDuration = (field) => {
    setDuration((prevDuration) => ({
      ...prevDuration,
      [field]: prevDuration[field] + 1
    }));
  };

  const decrementDuration = (field) => {
    setDuration((prevDuration) => ({
      ...prevDuration,
      [field]: prevDuration[field] > 0 ? prevDuration[field] - 1 : 0
    }));
  };

  const handleTripTypeChange = (event) => {
    setTripType(event.target.value);
    setDuration({ days: 0, hours: 0, minutes: 0 }); // Reset duration when trip type changes
  };

  const handleGroupSizeChange = (field, value) => {
    setGroupSize((prevSize) => ({
      ...prevSize,
      [field]: value > 0 ? value : 0
    }));
  };

  const handleContactChange = (field, value) => {
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value
    }));
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    history.push('/');
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              المدة
            </Typography>
            <Typography variant="body1" gutterBottom>
              كم من الوقت تريد أن تكون رحلتك أو الإيجار؟
            </Typography>
            <RadioGroup value={tripType} onChange={handleTripTypeChange}>
              <FormControlLabel value="same-day" control={<Radio />} label="العودة في نفس اليوم" />
              <FormControlLabel value="overnight" control={<Radio />} label="أكثر من ليلة" />
            </RadioGroup>
            {tripType === 'same-day' && (
              <Box className="duration-box">
                <Box className="duration-item">
                  <Button className="duration-button" onClick={() => decrementDuration('hours')}>-</Button>
                  <Typography>{duration.hours}</Typography>
                  <Button className="duration-button" onClick={() => incrementDuration('hours')}>+</Button>
                  <Typography>ساعات</Typography>
                </Box>
                <Box className="duration-item">
                  <Button className="duration-button" onClick={() => decrementDuration('minutes')}>-</Button>
                  <Typography>{duration.minutes}</Typography>
                  <Button className="duration-button" onClick={() => incrementDuration('minutes')}>+</Button>
                  <Typography>دقائق</Typography>
                </Box>
              </Box>
            )}
            {tripType === 'overnight' && (
              <Box className="duration-box">
                <Box className="duration-item">
                  <Button className="duration-button" onClick={() => decrementDuration('days')}>-</Button>
                  <Typography>{duration.days}</Typography>
                  <Button className="duration-button" onClick={() => incrementDuration('days')}>+</Button>
                  <Typography>أيام</Typography>
                </Box>
              </Box>
            )}
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ marginTop: 2 }}>
              التالي
            </Button>
          </div>
        );
      case 1:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              اختر التاريخ المفضل
            </Typography>
            <Typography variant="body1" gutterBottom>
              يرجى اختيار التاريخ المفضل لديك لرحلتك.
            </Typography>
            <DatePicker
              selected={preferredDate}
              onChange={(date) => setPreferredDate(date)}
              dateFormat="dd/MM/yyyy"
              className="date-picker"
            />
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ marginTop: 2 }}>
              التالي
            </Button>
          </div>
        );
      case 2:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              وقت المغادرة وحجم المجموعة
            </Typography>
            <Typography variant="body1" gutterBottom>
              يرجى اختيار وقت المغادرة وحجم المجموعة.
            </Typography>
            <TextField
              type="time"
              label="وقت المغادرة"
              value={departureTime}
              onChange={(e) => setDepartureTime(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
            />
            <Typography variant="body1" gutterBottom>
              حجم المجموعة
            </Typography>
            <Box className="group-size-box">
              <Box className="group-size-item">
                <Typography>البالغين</Typography>
                <Button className="group-size-button" onClick={() => handleGroupSizeChange('adults', groupSize.adults - 1)}>-</Button>
                <Typography>{groupSize.adults}</Typography>
                <Button className="group-size-button" onClick={() => handleGroupSizeChange('adults', groupSize.adults + 1)}>+</Button>
              </Box>
              <Box className="group-size-item">
                <Typography>كبار السن</Typography>
                <Button className="group-size-button" onClick={() => handleGroupSizeChange('seniors', groupSize.seniors - 1)}>-</Button>
                <Typography>{groupSize.seniors}</Typography>
                <Button className="group-size-button" onClick={() => handleGroupSizeChange('seniors', groupSize.seniors + 1)}>+</Button>
              </Box>
              <Box className="group-size-item">
                <Typography>الأطفال</Typography>
                <Button className="group-size-button" onClick={() => handleGroupSizeChange('children', groupSize.children - 1)}>-</Button>
                <Typography>{groupSize.children}</Typography>
                <Button className="group-size-button" onClick={() => handleGroupSizeChange('children', groupSize.children + 1)}>+</Button>
              </Box>
              <Box className="group-size-item">
                <Typography>الرضع</Typography>
                <Button className="group-size-button" onClick={() => handleGroupSizeChange('infants', groupSize.infants - 1)}>-</Button>
                <Typography>{groupSize.infants}</Typography>
                <Button className="group-size-button" onClick={() => handleGroupSizeChange('infants', groupSize.infants + 1)}>+</Button>
              </Box>
            </Box>
            <Typography variant="body1" gutterBottom>
              المجموع: {groupSize.adults + groupSize.seniors + groupSize.children + groupSize.infants} شخص
            </Typography>
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ marginTop: 2 }}>
              التالي
            </Button>
          </div>
        );
      case 3:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              معلومات إضافية
            </Typography>
            <Typography variant="body1" gutterBottom>
              يرجى إدخال أي معلومات إضافية أو طلبات خاصة.
            </Typography>
            <TextField
              label="معلومات إضافية"
              multiline
              rows={4}
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ marginTop: 2 }}>
              التالي
            </Button>
          </div>
        );
      case 4:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              تفاصيل الاتصال
            </Typography>
            <Typography variant="body1" gutterBottom>
              يرجى إدخال تفاصيل الاتصال الخاصة بك.
            </Typography>
            <TextField
              label="الاسم"
              value={contactDetails.name}
              onChange={(e) => handleContactChange('name', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="البريد الإلكتروني"
              value={contactDetails.email}
              onChange={(e) => handleContactChange('email', e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="رقم الهاتف"
              value={contactDetails.phone}
              onChange={(e) => handleContactChange('phone', e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleNext} sx={{ marginTop: 2 }}>
              التالي
            </Button>
          </div>
        );
      case 5:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              تأكيد
            </Typography>
            <Typography variant="body1" gutterBottom>
              تحقق من التفاصيل وأرسل استفسارك.
            </Typography>
            <Typography variant="body2" gutterBottom>
              مدة الرحلة: {tripType === 'same-day' ? `${duration.hours} ساعات ${duration.minutes} دقائق` : `${duration.days} أيام`}
            </Typography>
            <Typography variant="body2" gutterBottom>
              التاريخ المفضل: {preferredDate ? preferredDate.toLocaleDateString('ar-EG') : 'لم يتم اختياره'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              وقت المغادرة: {departureTime}
            </Typography>
            <Typography variant="body2" gutterBottom>
              حجم المجموعة: {groupSize.adults + groupSize.seniors + groupSize.children + groupSize.infants} شخص (البالغين: {groupSize.adults}، كبار السن: {groupSize.seniors}، الأطفال: {groupSize.children}، الرضع: {groupSize.infants})
            </Typography>
            <Typography variant="body2" gutterBottom>
              معلومات إضافية: {additionalInfo}
            </Typography>
            <Typography variant="body2" gutterBottom>
              تفاصيل الاتصال: {contactDetails.name}، {contactDetails.email}، {contactDetails.phone}
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)} sx={{ marginTop: 2 }}>
              إرسال
            </Button>
          </div>
        );
      default:
        return 'خطوة غير معروفة';
    }
  };

  return (
    <Container maxWidth="lg" className="inquiry-form-container">
      <Box className="navbar">
        <Typography variant="h6" gutterBottom>
          حجز رحلة
        </Typography>
        <CloseIcon className="close-icon" onClick={() => history.push('/')} />
      </Box>
      <Container maxWidth="lg" className="inquiry-form-container">
        <Box className="stepper-container">
          <Stepper activeStep={activeStep} connector={<StepConnector sx={{ '& .MuiStepConnector-line': { borderColor: '#3cbf94' } }} />}>
            {steps.map((label, index) => (
              <Step key={index}>
                <CustomStepLabel StepIconComponent={CustomStepIcon}>{label}</CustomStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box className="content-container">
          {renderStepContent(activeStep)}
          {activeStep > 0 && activeStep < steps.length - 1 && (
            <Box className="navigation-buttons">
              <Button onClick={handleBack} disabled={activeStep === 0}>
                العودة
              </Button>
            </Box>
          )}
        </Box>

        <Dialog open={openDialog} onClose={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'تأكيد إرسال الاستفسار'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">هل أنت متأكد من أنك تريد إرسال هذا الاستفسار؟</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              إلغاء
            </Button>
            <Button onClick={handleDialogClose} color="primary" autoFocus>
              تأكيد
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={handleSnackbarClose} severity="warning">
            {validationMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Container>
  );
};

export default InquiryForm;
