import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ListingWidget from '../../components/Listings/ListingWidget';
import axios from 'axios'; // Axios for API requests
import './Listing.css';
import config from "../../config/config"; // Import the config file

const Listing = () => {
  const [listings, setListings] = useState([]); // State to store listings
  const [loading, setLoading] = useState(true); // State to handle loading

  // Fetch listings from the API
  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/listings/`);
        setListings(response.data); // Set the listings data from the API response
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching listings:', error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchListings(); // Call the function to fetch listings
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>; // Show loading indicator
  }

  return (
    <Container maxWidth="xl" sx={{ padding: '20px' }}>
      <Grid container spacing={3}>
        {listings.map((listing, index) => (
          <Grid
            item
            xs={12} // 1 item per row on mobile
            sm={6} // 2 items per row on tablets
            md={3} // 3 items per row on desktops and larger screens
            key={index}
          >
            <Link to={`/listing/${listing.id}`} style={{ textDecoration: 'none' }}>
              <ListingWidget
                id={listing.id}
                title={listing.title}
                description={listing.description}
                images={listing.Images.length > 0 ? [`${config.imageBaseUrl}/${listing.Images[0].url}`] : []} // Pass only the first image
                position={`${listing.latitude}, ${listing.longitude}`} // Use latitude and longitude for position
                reviews={listing.Reviews.length} // Use number of reviews
                priceRange={`${listing.Prices[0]?.start_price} رس  - ${listing.Prices[0]?.end_price} رس`} // Display price range
                guests={listing.Availabilities.length} // Assuming guests based on availability (adjust as needed)
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Listing;
