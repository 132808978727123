import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const FooterContainer = styled('footer')({
  width: '100%',
  backgroundColor: '#fff',
  color: '#000',
  padding: '40px 16px',
  display: 'flex',
  justifyContent: 'center',
  direction: 'ltr', // Set the overall direction to RTL
});

const ContentContainer = styled(Container)({
  maxWidth: '1200px',
  display: 'flex',
  justifyContent: 'space-between',
});

const Column = styled(Box)({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '20px',
  marginRight: '20px',
});

const FooterHeading = styled(Typography)({
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '16px',
});

const FooterButton = styled(Button)({
  color: '#686D76',
  textTransform: 'none',
  padding: '4px 0',
  justifyContent: 'flex-start',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
});

const Footer = () => {
  return (
    <FooterContainer>
      <ContentContainer>
        <Column>
          <FooterHeading>حول</FooterHeading>
          <FooterButton>حول Marisa</FooterButton>
          <FooterButton>الوظائف</FooterButton>
          <FooterButton>استفسارات وسائل الإعلام</FooterButton>
          <FooterButton>شروط الاستخدام</FooterButton>
          <FooterButton>سياسة الخصوصية</FooterButton>
          <FooterButton>سياسة ملفات تعريف الارتباط</FooterButton>
          <FooterButton>بيان إمكانية الوصول</FooterButton>
          <FooterButton>اتفاقية واجهة العضو</FooterButton>
          <FooterButton>اتصل بنا</FooterButton>
        </Column>

        <Column>
          <FooterHeading>تعرف على المزيد</FooterHeading>
          <FooterButton>كيف تعمل</FooterButton>
          <FooterButton>للأصحاب</FooterButton>
          <FooterButton>تطبيقات الجوال</FooterButton>
          <FooterButton>السلامة</FooterButton>
          <FooterButton>التجارب</FooterButton>
        </Column>

        <Column>
          <FooterHeading>تأجير القوارب</FooterHeading>
          <FooterButton>تأجير الجت سكي</FooterButton>
          <FooterButton>رحلات الصيد</FooterButton>
          <FooterButton>تأجير القوارب المنزلية</FooterButton>
          <FooterButton>تأجير القوارب العائمة</FooterButton>
          <FooterButton>تأجير اليخوت</FooterButton>
          <FooterButton>تأجير قوارب الإبحار</FooterButton>
          <FooterButton>تأجير قارب للحفلات</FooterButton>
        </Column>

        <Column>
          <FooterHeading>الدليل</FooterHeading>
          <FooterButton>أخبار الشركة</FooterButton>
          <FooterButton>أسلوب الحياة</FooterButton>
          <FooterButton>دليل التجارب</FooterButton>
          <FooterButton>الوجهات</FooterButton>
          <FooterButton>نصائح للمستأجرين والمالكين</FooterButton>
        </Column>

        {/* <Column>
          <FooterHeading>الوجهات الشعبية</FooterHeading>
          <FooterButton>ميامي</FooterButton>
          <FooterButton>بحيرة لانير</FooterButton>
          <FooterButton>بحيرة تاهو</FooterButton>
          <FooterButton>سان دييغو</FooterButton>
          <FooterButton>بحيرة ترافيس</FooterButton>
          <FooterButton>شاطئ نيوبورت</FooterButton>
          <FooterButton>شيكاغو</FooterButton>
          <FooterButton>بحيرة نورمان</FooterButton>
          <FooterButton>كي لارغو</FooterButton>
          <FooterButton>ديستين</FooterButton>
          <FooterButton>ماراثون</FooterButton>
        </Column> */}

        <Column>
          <FooterHeading>دعم على مدار الساعة</FooterHeading>
          <FooterButton>المساعدة والأسئلة الشائعة</FooterButton>
          <FooterButton>+1 818 927 2148</FooterButton>
          <FooterButton>support@marisa.com</FooterButton>
        </Column>
      </ContentContainer>
    </FooterContainer>
  );
};

export default Footer;
