import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: null,
    user: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      // Retrieve token and user from localStorage
      const token = localStorage.getItem('authToken');
      const user = localStorage.getItem('authUser');

      if (token && user) {
        setAuthState({
          token: token,
          user: JSON.parse(user),
        });
      }
    } catch (error) {
      console.error('Error retrieving auth data', error);
    } finally {
      setLoading(false); // Ensure loading is set to false after attempting to load auth data
    }
  }, []);

  const saveAuthState = (authData) => {
    setAuthState(authData);
    localStorage.setItem('authToken', authData.token);
    localStorage.setItem('authUser', JSON.stringify(authData.user));
  };

  const logout = () => {
    setAuthState({
      token: null,
      user: null,
    });
    localStorage.removeItem('authToken');
    localStorage.removeItem('authUser');
  };

  return (
    <AuthContext.Provider value={{ authState, saveAuthState, logout, loading }}>
      {!loading && children} {/* Render children only after checking the auth state */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
