import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Chip,
  DialogContent,
  Divider,
  Box,
  IconButton,
  Typography,
  Slide,
  Drawer,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Slider,
  Rating,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/system";
import axios from "axios";
import "./Categories.css";
import config from "../../apiConfig";

const StyledButton = styled(Button)({
  color: "black",
  textTransform: "none",
  fontSize: "0.875rem",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const CustomChip = styled(Chip)(({ theme, selected }) => ({
  height: "40px",
  fontSize: "14px",
  borderColor: selected ? "#44d8a4" : theme.palette.grey[300],
  borderWidth: "1px",
  borderStyle: "solid",
  backgroundColor: "white",
  color: "black",
  "&:hover": {
    borderColor: selected ? "#44d8a4" : "#cccccc",
  },
}));

const CustomFormControl = styled(FormControl)(({ theme, borderRadius, color }) => ({
  width: "200px",
  borderRadius: borderRadius || "20px",
  marginTop: "16px",
  marginBottom: "16px",
  "& .MuiOutlinedInput-root": {
    borderRadius: borderRadius || "20px",
    backgroundColor: color || "white",
    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
    },
    "&.Mui-focused fieldset": {
      borderColor: "#44d8a4",
    },
  },
  "& .MuiSelect-select": {
    borderRadius: borderRadius || "20px",
  },
}));

const FilterButton = styled(Box)(({ theme }) => ({
  color: "black",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  border: `1px solid ${theme.palette.grey[300]}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filter, setFilter] = useState("");
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [priceData, setPriceData] = useState([]);
  const [selectedChip, setSelectedChip] = useState(null);
  const [minRating, setMinRating] = useState(0);
  const [maxRating, setMaxRating] = useState(0);
  const [availability, setAvailability] = useState('all');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${config.categoryService}/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        const response = await axios.get(`${config.categoryService}/listings/price-data`);
        setPriceData(response.data);
      } catch (error) {
        console.error("Error fetching price data:", error);
      }
    };

    fetchPriceData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleCategoryClick = (index) => {
    setSelectedCategory(index);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const handleChipClick = (value) => {
    setSelectedChip(value);
  };

  const handleAvailabilityChange = (event) => {
    setAvailability(event.target.value);
  };

  return (
    <div className="categories-section">
     
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        px={4}
      >
        <CustomFormControl variant="outlined" sx={{ mr: 2 }}>
          <Select
            value={filter}
            onChange={handleFilterChange}
            displayEmpty
            inputProps={{ "aria-label": "Filter" }}
          >
            <MenuItem value="" disabled>
              اختر
            </MenuItem>
            <MenuItem value="higher">السعر: من الأعلى إلى الأدنى</MenuItem>
            <MenuItem value="lower">السعر: من الأدنى إلى الأعلى</MenuItem>
            <MenuItem value="newest">الأحدث</MenuItem>
            <MenuItem value="oldest">الأقدم</MenuItem>
          </Select>
        </CustomFormControl>
        <FilterButton onClick={handleDrawerOpen}>
          <FilterListIcon />
        </FilterButton>
      </Box>
      {/* <div className="categories-container">
        {categories.slice(0, 5).map((category, index) => (
          <div
            key={index}
            className={`category-item ${
              selectedCategory === index ? "selected" : ""
            }`}
            onClick={() => handleCategoryClick(index)}
          >
            <img
              src={`${config.categoryService}/${category.image}`}
              alt={category.name}
              className="category-image"
            />
            <p className="category-name">{category.name}</p>
          </div>
        ))}
      </div>
      <StyledButton variant="text" onClick={handleClickOpen}>
        المزيد
      </StyledButton> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="see-more-dialog"
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
        PaperProps={{
          style: {
            margin: 0,
            borderRadius: "20px 20px 0 0",
            width: "100%",
            position: "fixed",
            bottom: 0,
          },
        }}
        fullWidth
      >
        <DialogContent className="all-categories-container">
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", right: 16, top: 16 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            جميع الفئات
          </Typography>
          <div className="categories-container">
            {categories.map((category, index) => (
              <div
                key={index}
                className={`category-item ${
                  selectedCategory === index ? "selected" : ""
                }`}
                onClick={() => handleCategoryClick(index)}
              >
                <img
                  src={`${config.categoryService}/${category.image}`}
                  alt={category.name}
                  className="category-image"
                />
                <p className="category-name">{category.name}</p>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          style: {
            borderRadius: "20px 0 0 20px",
            direction: "rtl",
            width: "30%",
          },
        }}
      >
        <Box p={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">خيارات الفرز</Typography>
            <IconButton
              onClick={handleDrawerClose}
              sx={{ position: "relative" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ mb: 2, mt: 2 }}>
            <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
              النطاق السعري
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                placeholder="الحد الأدنى"
                type="number"
                value={minPrice}
                onChange={handleMinPriceChange}
                sx={{
                  width: "45%",
                  borderRadius: "50px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "50px",
                    "&.Mui-focused fieldset": {
                      borderColor: "#44d8a4",
                    },
                  },
                }}
              />
              <TextField
                placeholder="الحد الأقصى"
                type="number"
                value={maxPrice}
                onChange={handleMaxPriceChange}
                sx={{
                  width: "45%",
                  borderRadius: "50px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "50px",
                    "&.Mui-focused fieldset": {
                      borderColor: "#44d8a4",
                    },
                  },
                }}
              />
            </Box>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={1000}
              sx={{
                mt: 3,
                color: "#44d8a4",
                "& .MuiSlider-thumb": { color: "#44d8a4" },
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {priceData.map((price, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "2px",
                    height: `${price.count * 10}px`,
                    backgroundColor: "#44d8a4",
                  }}
                />
              ))}
            </Box>
          </Box>
          <Divider className="devider" />
          <Box sx={{ mb: 2, mt: 2 }}>
            <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
              سعة
            </Typography>
            <Box display="flex" gap={1} flexWrap="wrap">
              {Array.from({ length: 8 }, (_, i) => (
                <CustomChip
                  key={i}
                  label={i + 1}
                  selected={selectedChip === i + 1}
                  onClick={() => handleChipClick(i + 1)}
                />
              ))}
            </Box>
          </Box>
          <Divider className="devider" />
          <Box sx={{ mb: 2, mt: 2 }}>
            <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
              التقييمات
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Typography variant="body2">الحد الأدنى</Typography>
              <Rating
                name="min-rating"
                value={minRating}
                onChange={(event, newValue) => {
                  if (newValue <= maxRating) {
                    setMinRating(newValue);
                  }
                }}
              />
              <Typography variant="body2">الحد الأقصى</Typography>
              <Rating
                name="max-rating"
                value={maxRating}
                onChange={(event, newValue) => {
                  if (newValue >= minRating) {
                    setMaxRating(newValue);
                  }
                }}
              />
            </Box>
          </Box>
          <Divider className="devider" />
          <Box sx={{ mb: 2, mt: 2 }}>
            <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
              التوفر
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={availability}
                onChange={handleAvailabilityChange}
              >
                <FormControlLabel
                  value="available"
                  control={<Radio sx={{ color: "#44d8a4",'&.Mui-checked': { color: "#44d8a4" } }} />}
                  label="متاح"
                />
                <FormControlLabel
                  value="notAvailable"
                  control={<Radio sx={{ color: "#44d8a4",'&.Mui-checked': { color: "#44d8a4" } }} />}
                  label="غير متاح"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio sx={{ color: "#44d8a4",'&.Mui-checked': { color: "#44d8a4" } }} />}
                  label="الكل"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default Categories;
