import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box, Rating } from '@mui/material';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import './ListingWidget.css';

const ListingWidget = ({ title, description, images, position, reviews, priceRange, guests }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  return (
    <Card className="listing-widget">
      <Box position="relative">
        {/* Display the first image */}
        {images && images.length > 0 ? (
          <img
            src={images[0]} // Since you're passing only the first image, this will always be the first image
            alt={title}
            className='listing-image'
            style={{ width: '100%', height: '200px', objectFit: 'cover' }}
          />
        ) : (
          <img
            src="/path/to/placeholder.jpg" // Optional: Placeholder image if no images are provided
            alt="Placeholder"
            className='listing-image'
            style={{ width: '100%', height: '200px', objectFit: 'cover' }}
          />
        )}
        
        {/* Favorite Icon */}
        <IconButton
          onClick={toggleFavorite}
          color="primary"
          className="favorite-icon"
        >
          {isFavorite ? <Favorite /> : <FavoriteBorder />}
        </IconButton>
      </Box>
      
      <CardContent>
        <Box display="flex" justifyContent="space-between">
        <Typography 
  gutterBottom 
  variant="h5" 
  component="div"
  sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: '#333' }}
>
  {title ? (title.length > 20 ? `${title.substring(0, 20)}...` : title) : "Title not available"}
</Typography>


          <Rating 
            value={reviews} 
            readOnly 
            sx={{
              '& .MuiRating-iconFilled': {
                color: '#f9d978', // Change the color of the filled stars
              },
              '& .MuiRating-iconHover': {
                color: '#f9d978', // Change the color of the hovered stars
              },
            }} 
          />
        </Box>
       
        <Typography 
          variant="body2" 
          color="textSecondary" 
          component="p" 
          noWrap
          sx={{ fontSize: '.9rem', color: '#888', marginTop: '8px' }}
        >
          {description}
        </Typography>
        
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Typography 
            variant="body2" 
            color="textSecondary" 
            component="p"
            sx={{ fontSize: '.9rem', color: '#000', fontWeight: 'bold' }}
          >
            {priceRange} للساعة
          </Typography>
          <Typography 
            variant="body2" 
            color="textSecondary" 
            component="p"
            sx={{ fontSize: '.65rem', color: '#888', fontWeight: 'bold' }}
          >
            {guests} ضيوف
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ListingWidget;
