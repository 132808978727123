import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useSpring, animated } from 'react-spring'; // Import animated from react-spring
import EditIcon from '@mui/icons-material/Edit';
import Navbar from '../../components/Navbar/Navbar';
import MobileNavbar from '../../components/Navbar/MobileNavbar';
import { useAuth } from '../../services/AuthContext';
import axios from 'axios';
import apiConfig from '../../apiConfig';
import './UserProfile.css';

// Define the styled button here
const StyledButton = styled(Button)({
  backgroundColor: '#3cbf94',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#32a682',
  },
  borderRadius: '25px',
  padding: '10px 20px',
  textTransform: 'none',
  fontSize: '16px',
});

// Define the styled text field
const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
    backgroundColor: '#f5f5f5',
    '& fieldset': {
      borderColor: '#C5C5C5',
    },
    '&:hover fieldset': {
      borderColor: '#3cbf94',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#C5C5C5',
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem',
  },
  '& .MuiInputLabel-root': {
    color: '#777',
    fontWeight: 'bold',
  },
  '& .MuiInputLabel-shrink': {
    color: '#C5C5C5 !important',
  },
});

const UserProfile = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const { authState } = useAuth(); // Access authentication state
  const [user, setUser] = useState({
    profilePicture: '', // Use profilePicture instead of avatar
    name: '',
    surname: '',
    email: '',
    password: '',
    phone: '',
    languageSpoken: '', // Use languageSpoken to match the backend
    description: '',
  });
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [preview, setPreview] = useState(null);
  const history = useHistory();

  // Fetch the authenticated user data from API when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiConfig.userService}/users/auth-user`, {
          headers: {
            Authorization: `Bearer ${authState.token}`,
          },
        });
        const fetchedUser = response.data;

        setUser({
          profilePicture: fetchedUser.profilePicture || '', // Make sure to use profilePicture
          name: fetchedUser.name || '',
          surname: fetchedUser.surname || '',
          email: fetchedUser.email || '',
          password: '', // We don't want to display the actual password
          phone: fetchedUser.phoneNumber || '',
          languageSpoken: fetchedUser.languageSpoken || '', // Make sure to use languageSpoken
          description: fetchedUser.description || '',
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [authState.token]);

  const handleChange = (field, value) => {
    setUser((prevUser) => ({ ...prevUser, [field]: value }));
    setIsSaveVisible(true);
  };

  const handleSave = async () => {
    console.log('Saving user details:', user);
    setIsSaveVisible(false);
    try {
      await axios.put(
        `${apiConfig.userService}/users/profile`,
        { ...user },
        {
          headers: {
            Authorization: `Bearer ${authState.token}`,
          },
        }
      );
      console.log('User details updated successfully');
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result); // Display the preview locally
      };
      reader.readAsDataURL(file);
  
      // Upload the image separately
      const formData = new FormData();
      formData.append('avatar', file); // 'avatar' should match the field name expected by the backend
  
      try {
        const response = await axios.put(`${apiConfig.userService}/users/upload-avatar`, formData, {
          headers: {
            Authorization: `Bearer ${authState.token}`,
            'Content-Type': 'multipart/form-data', // Ensure the correct content type
          },
        });
        console.log('Image uploaded successfully:', response.data);

        // Update user profile picture after successful upload
        setUser((prevUser) => ({ ...prevUser, profilePicture: response.data.user.profilePicture }));
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const animationProps = useSpring({
    opacity: isSaveVisible ? 1 : 0,
    config: { tension: 220, friction: 20 },
  });

  return (
    <Container maxWidth="xl" className="profile-page-container">
      {isMobile ? <MobileNavbar /> : <Navbar />}
      <Container maxWidth="sm" className="profile-container">
        <Box className="profile-header">
          <Avatar
            alt="صورة المستخدم"
            src={preview || `${apiConfig.userService}/${user.profilePicture}`} // Display profilePicture when available
            className="profile-avatar"
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="avatar-upload"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="avatar-upload" style={{ position: 'absolute', top: "33%", right: "47%" }}>
            <IconButton component="span" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
              <EditIcon />
            </IconButton>
          </label>
          <Typography variant="h5">ملف المستخدم</Typography>
        </Box>
        <Box className="profile-form">
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
            يرجى استخدام اسم حقيقي بدلاً من اسم الشركة لتسهيل التواصل.
          </Typography>
          <Box className="name-surname">
            <StyledTextField
              label="الاسم"
              value={user.name}
              onChange={(e) => handleChange('name', e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <StyledTextField
              label="اللقب"
              value={user.surname}
              onChange={(e) => handleChange('surname', e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Box>
         
          <StyledTextField
            label="البريد الإلكتروني"
            type="email"
            value={user.email}
            onChange={(e) => handleChange('email', e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            style={{ marginBottom: '20px' }}
          />
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
            <span className="clickable-text" onClick={() => console.log('تغيير البريد الإلكتروني')}>تغيير عنوان البريد الإلكتروني</span>
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '20px' }}>
            يتم مشاركة عنوان بريدك الإلكتروني فقط بعد إجراء حجز.
          </Typography>
          <StyledTextField
            label="كلمة المرور"
            type="password"
            value={user.password}
            onChange={(e) => handleChange('password', e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            style={{ marginBottom: '20px' }}
          />
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
            <span className="clickable-text" onClick={() => console.log('تغيير كلمة المرور')}>تغيير كلمة المرور</span>
          </Typography>
          <Box className="phone-field">
            <StyledTextField
              label="الهاتف"
              value={user.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
              alt="علم السعودية"
              className="saudi-flag"
            />
          </Box>
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
            <span className="clickable-text" onClick={() => console.log('تغيير رقم الهاتف')}>تغيير رقم الهاتف | التحقق من رقم الجوال</span>
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '20px' }}>
            يتم مشاركة رقم هاتفك فقط بعد إجراء حجز.
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
            لإعلام الآخرين بأنهم يمكنهم التواصل معك بهذه اللغات.
          </Typography>
          <StyledTextField
            label="اللغة المحكية"
            value={user.languageSpoken} // Make sure to use languageSpoken
            onChange={(e) => handleChange('languageSpoken', e.target.value)} // Update with correct field name
            fullWidth
            margin="normal"
            variant="outlined"
            style={{ marginBottom: '20px' }}
          />
          <StyledTextField
            label="الوصف"
            value={user.description}
            onChange={(e) => handleChange('description', e.target.value)}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            variant="outlined"
          />
        </Box>
      </Container>
      {isSaveVisible && (
        <animated.div className="save-button-container-of">
          <StyledButton
            variant="contained"
            color="primary"
            className="save-button"
            onClick={handleSave}
          >
            حفظ
          </StyledButton>
        </animated.div>
      )}
    </Container>
  );
};

export default UserProfile;
