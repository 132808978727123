import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Select,
  TextField,
  MenuItem,
  Divider,
  InputAdornment,
  FormControl,
  InputLabel,
  Popover,
} from '@mui/material';
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import MailIcon from '@mui/icons-material/Mail';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HelpIcon from '@mui/icons-material/Help';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useLanguage } from '../../services/LanguageContext';
import translations from '../../Lang/translations';
import { useAuth } from '../../services/AuthContext'; // Assuming you have this hook
import './Navbar.css';
import logo from '../../assets/logo M.png'; // Import your logo image

const NoArrowIcon = () => null;

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState('riyadh');
  const [selectedDate, setSelectedDate] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const history = useHistory();
  const { language, changeLanguage } = useLanguage();
  const t = translations['ar'];
  const { authState, logout } = useAuth(); // Assuming useAuth provides authState and logout
  const isAuthenticated = !!authState.token;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleDropdownOpen = (index) => {
    setOpenDropdown(index);
  };

  const handleDropdownClose = () => {
    setOpenDropdown(null);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDatePickerOpen(false);
  };

  const handleDateClick = (e) => {
    e.stopPropagation();
    setDatePickerOpen(!datePickerOpen);
  };

  const handleCreatelisting = () => {
    history.push('/create/listing');
    handleClose();
  };

  const handleCreateAccount = () => {
    history.push('/register');
    handleClose();
  };

  const handleSignIn = () => {
    history.push('/signin');
    handleClose();
  };

  const handleMyProfile = () => {
    history.push('/profile');
    handleClose();
  };

  const handleInbox = () => {
    history.push('/inbox');
    handleClose();
  };

  const handleFavourite = () => {
    history.push('/favourites');
    handleClose();
  };

  const handleSignOut = () => {
    logout();
    handleClose();
    history.push('/'); // Redirect to home after sign out
  };

  const handleLogoClick = () => {
    history.push('/'); // Navigate to the home page when logo is clicked
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: '1px solid #e0e0e0',
        padding: { xs: '0 10px', sm: '0 20px', md: '0 40px' },
      }}
    >
      <Box sx={{ direction: 'ltr', width: '100%' }}>
        <Toolbar className="navbar-toolbar">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Wrap the logo with IconButton and handle onClick */}
            <IconButton edge="start" color="inherit" aria-label="logo" onClick={handleLogoClick}>
              <img src={logo} alt="Logo" style={{ width: '100px', height: 'auto' }} />
            </IconButton>
          </Box>
          <div className="navbar-search">
            <FormControl
              variant="standard"
              className={`animated-select ${openDropdown === 1 ? 'expanded' : ''}`}
              onMouseEnter={() => handleDropdownOpen(1)}
              onMouseLeave={handleDropdownClose}
              sx={{
                padding: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <InputLabel
                shrink
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  padding: '0 20px',
                }}
              >
                {t.whereTo}
              </InputLabel>
              <Select
                value={selectedOption1}
                onChange={(e) => setSelectedOption1(e.target.value)}
                onOpen={() => handleDropdownOpen(1)}
                onClose={handleDropdownClose}
                disableUnderline
                IconComponent={NoArrowIcon}
                sx={{ fontSize: '1rem', fontWeight: 'bold' }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      transform: 'translateY(0px)',
                      transition: 'transform 0.3s ease-in-out',
                    },
                  },
                }}
              >
                {/* Menu Items */}
              </Select>
            </FormControl>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <FormControl
              variant="standard"
              sx={{
                padding: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
              onClick={handleDateClick}
            >
              <InputLabel
                shrink
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  padding: '0 20px',
                }}
              >
                {t.when}
              </InputLabel>
              <Box
                sx={{
                  fontSize: '1rem',
                  fontWeight: '600',
                  padding: '22px 0px 10px 0px',
                  color: selectedDate ? 'black' : 'inherit',
                }}
              >
                {selectedDate ? dayjs(selectedDate).format('MM/DD/YYYY') : t.selectDate}
              </Box>
            </FormControl>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              onClickOutside={() => setDatePickerOpen(false)}
              open={datePickerOpen}
              customInput={<Box sx={{ display: 'none' }} />}
            />
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            <TextField
              placeholder={t.search}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                sx: { fontSize: '1rem', fontWeight: 'bold', padding: '0 5px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        backgroundColor: '#44d8a4',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <SearchIcon sx={{ color: 'white', fontSize: '24px' }} />
                    </Box>
                  </InputAdornment>
                ),
              }}
              sx={{ flex: 2, padding: '0 10px', width: { xs: '100%', sm: 'auto' } }}
            />
          </div>
          <Box className="navbar-right-container">
            <div className="clickable-icon-container" onClick={handleClick}>
              <IconButton sx={{ mx: 1, padding: '10px' }} disableRipple>
                <MenuIcon style={{ color: 'black', fontSize: '25px' }} />
              </IconButton>
              <IconButton sx={{ mx: 1, padding: '1px' }} disableRipple>
                <AccountCircleIcon style={{ color: 'black', fontSize: '25px' }} />
              </IconButton>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {
                  p: 2,
                  width: 200,
                  borderRadius: '20px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              {isAuthenticated ? (
                <>
                  <MenuItem onClick={handleMyProfile}>
                    <AccountCircleIcon sx={{ marginRight: 1, color: '#ccc', fontSize: '1rem' }} />
                    ملفي الشخصي
                  </MenuItem>
                  <MenuItem onClick={handleCreatelisting}>
                    <ListAltIcon sx={{ marginRight: 1, color: '#ccc', fontSize: '1rem' }} />
                    {t.createListing}
                  </MenuItem>
                  <MenuItem onClick={handleInbox}>
                    <MailIcon sx={{ marginRight: 1, color: '#ccc', fontSize: '1rem' }} />
                    البريد الوارد
                  </MenuItem>
                  <MenuItem onClick={handleFavourite}>
                    <FavoriteIcon sx={{ marginRight: 1, color: '#ccc', fontSize: '1rem' }} />
                   المفضلات
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleSignOut}>
                    <ExitToAppIcon sx={{ marginRight: 1, color: '#ccc', fontSize: '1rem' }} />
                    تسحيل الخروج
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={handleClose}>
                    <HelpIcon sx={{ marginRight: 1, color: '#ccc', fontSize: '1rem' }} />
                    {t.help}
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleCreateAccount}>
                    <PersonAddIcon sx={{ marginRight: 1, color: '#ccc', fontSize: '1rem' }} />
                    {t.createAccount}
                  </MenuItem>
                  <MenuItem onClick={handleSignIn}>
                    <LockOpenIcon sx={{ marginRight: 1, color: '#ccc', fontSize: '1rem' }} />
                    {t.signIn}
                  </MenuItem>
                </>
              )}
            </Popover>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Navbar;
