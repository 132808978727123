import React, { useState } from 'react';
import { Container, Box, IconButton, Grid, TextField, Button, Typography, Link, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useAuth } from '../../services/AuthContext';
import { useLanguage } from '../../services/LanguageContext';
import Toast, { showToast } from '../../services/Toast';
import translations from '../../Lang/translations';
import { useHistory } from 'react-router-dom';
import './Register.css';
import config from "../../config/config"; // Import the config file

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { saveAuthState } = useAuth();
  const { language } = useLanguage();
  const t = translations['ar'];
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      showToast(t.passwordMismatch, 'error');
      return;
    }

    try {
      const response = await axios.post(`${config.userService}/register`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      saveAuthState({
        token: response.data.token,
        user: response.data.user,
      });
      showToast(t.registrationSuccess, 'success');
    } catch (error) {
      console.error('Registration error', error);
      showToast(t.registrationFailed, 'error');
    }
  };

  const handleClose = () => {
    history.push('/'); // Navigate to the home screen
  };

  return (
    <Container className="register-container" sx={{ direction: 'rtl' }}>
      <Toast />
      <Box
        className="register-box"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          fontFamily: "MyriadPro",
          position: 'relative',
        }}
      >
        <IconButton className="close-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h3" mb={2} className="signin-heading" style={{ fontFamily: 'MyriadPro-Regular' }}>
          {t.createAccount}
        </Typography>
        <Typography variant="h5" mb={2} className="register-heading">
        {t.introduction}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box dir={ 'rtl' }>
              <TextField
                fullWidth
                label={t.firstName}
                variant="outlined"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="animated-textfield"
                InputLabelProps={{ className: 'textfield-label' }}
                inputProps={{ style: { direction:  'rtl'  } }}
                sx={{
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#44d8a4',
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box dir={ 'rtl'}>
              <TextField
                fullWidth
                label={t.lastName}
                variant="outlined"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="animated-textfield"
                InputLabelProps={{ className: 'textfield-label' }}
                inputProps={{ style: { direction:  'rtl'  } }}
                sx={{
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#44d8a4',
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box dir={ 'rtl'}>
              <TextField
                fullWidth
                label={t.email}
                variant="outlined"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="animated-textfield"
                InputLabelProps={{ className: 'textfield-label' }}
                inputProps={{ style: { direction: 'rtl'  } }}
                sx={{
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#44d8a4',
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box dir={ 'rtl' }>
              <TextField
                fullWidth
                label={t.phone}
                variant="outlined"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="animated-textfield"
                InputLabelProps={{ className: 'textfield-label' }}
                inputProps={{ style: { direction: 'rtl' } }}
                sx={{
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#44d8a4',
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box dir={ 'rtl'}>
              <TextField
                fullWidth
                label={t.password}
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="animated-textfield"
                InputLabelProps={{ className: 'textfield-label' }}
                inputProps={{ style: { direction: 'rtl' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#44d8a4',
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box dir={ 'rtl' }>
              <TextField
                fullWidth
                label={t.confirmPassword}
                variant="outlined"
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="animated-textfield"
                InputLabelProps={{ className: 'textfield-label' }}
                inputProps={{ style: { direction: 'rtl'  } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#44d8a4',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#44d8a4',
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div className="buttons">
              <Button variant="contained" color="primary" fullWidth className="btn" onClick={handleSubmit}>
                {t.createAccount}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className="register-link">
              {t.alreadyHaveAccount} <Link href="/signin" underline="hover" className='sign-in-btn'>{t.signIn}</Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Register;
