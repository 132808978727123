const translations = {
  en: {
    createAccount: 'Create Account',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    phone: 'Phone Number',
    password: 'Password',
    confirmPassword: 'Re-enter Password',
    alreadyHaveAccount: 'Already have an account?',
    signIn: 'Sign In',
    registrationFailed: 'Registration failed. Please try again.',
    registrationSuccess: 'Registration successful!',
    passwordMismatch: 'Passwords do not match!',
    whereTo: 'Where To',
    when: 'When',
    selectDate: 'Select a date here',
    search: 'Search…',
    option1: 'Option 1',
    option2: 'Option 2',
    option3: 'Option 3',
    createListing: 'Create Listing',
    help: 'Help',
    signInDescription: 'Please enter your email and password to sign in to your account.',
    dontHaveAccount: "Don't have an account?",
    protectedBy: 'This site is protected by reCAPTCHA and the',
    privacyPolicy: 'Privacy Policy',
    and: 'and',
    termsOfService: 'Terms of Service',
    apply: 'apply.',
    forgotPassword: 'Forgot Password?',
    signinSuccess: 'Sign in successful!',
    signinFailed: 'Sign in failed. Please try again.',
    introduction: 'Please fill in the details to create a new account.',

  },
  ar: {
    createAccount: 'إنشاء حساب',
    firstName: 'الاسم الأول',
    lastName: 'اسم العائلة',
    email: 'عنوان البريد الإلكتروني',
    phone: 'رقم الهاتف',
    password: 'كلمة المرور',
    confirmPassword: 'أعد إدخال كلمة المرور',
    alreadyHaveAccount: 'هل لديك حساب؟',
    signIn: 'تسجيل الدخول',
    registrationFailed: 'فشل في التسجيل. حاول مرة أخرى.',
    registrationSuccess: 'تم التسجيل بنجاح!',
    passwordMismatch: 'كلمات المرور غير متطابقة!',
    whereTo: 'إلى أين',
    when: 'متى',
    selectDate: 'حدد تاريخ هنا',
    search: 'بحث…',
    option1: 'الخيار 1',
    option2: 'الخيار 2',
    option3: 'الخيار 3',
    createListing: 'إنشاء قائمة',
    help: 'مساعدة',
    signInDescription: 'يرجى إدخال بريدك الإلكتروني وكلمة المرور لتسجيل الدخول إلى حسابك.',
    dontHaveAccount: 'لا تملك حساب؟',
    protectedBy: 'هذا الموقع محمي بواسطة reCAPTCHA و',
    privacyPolicy: 'سياسة الخصوصية',
    and: 'و',
    termsOfService: 'شروط الخدمة',
    apply: 'تنطبق.',
    forgotPassword: 'نسيت كلمة المرور؟',
    signinSuccess: 'تم تسجيل الدخول بنجاح!',
    signinFailed: 'فشل في تسجيل الدخول. حاول مرة أخرى.',
    introduction: 'يرجى ملء التفاصيل لإنشاء حساب جديد.',
  },
};

export default translations;
