import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import MobileNavbar from '../../components/Navbar/MobileNavbar';

import Categories from '../../components/Cateogries/Categories';
import Listing from '../../pages/Listing/Listing';
import About from '../../pages/AboutUs/AboutUs';

import { useMediaQuery } from '@mui/material';

import './Home.css';
import Variety from '../../components/Variety/Variety'; // Import the Variety component
import Reviews from '../../components/Reviews/Reviews'; // Import the Variety component
import GrowBusinessSection from '../../components/GrowBusinessSection/GrowBusinessSection'; // Import the Variety component
import Footer from '../../components/Footer/Footer'; // Import the Variety component

const Home = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  return (
    <div>         
     
{ isMobile ? <MobileNavbar /> : <Navbar />}
         <Categories />
         <Listing />
         <Variety />
         <Reviews />

         <About />
         <GrowBusinessSection />
         <Footer />


    </div>
  );
};

export default Home;
