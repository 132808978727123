import React, { useState } from 'react';
import { Container, Box, Typography, Button, Stepper, Step, StepLabel, Radio, RadioGroup, FormControlLabel, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import boatAnimation from './boat-anim.json';
import './CreateListing.css';

const steps = ['الخطوة 1', 'الخطوة 2', 'الخطوة 3', 'الخطوة 4', 'الخطوة 5'];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateListing = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const history = useHistory();

  const handleNext = () => {
    if (!selectedOption && activeStep !== steps.length - 1) {
      setOpenSnackbar(true);
    } else {
      if (activeStep < steps.length - 1) {
        setActiveStep((prevStep) => prevStep + 1);
        setSelectedOption('');
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleHomeRedirect = () => {
    history.push('/');
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              قبل أن نبدأ، يرجى إخبارنا قليلاً عن قاربك وكيف تعمل:
            </Typography>
            <Typography variant="body1" gutterBottom>
              أي من الخيارات التالية تصفك؟ هذا السؤال مطلوب.
            </Typography>
            <RadioGroup value={selectedOption} onChange={handleOptionChange}>
              <FormControlLabel value="individual" control={<Radio />} label="أنا فرد" />
              <FormControlLabel value="company" control={<Radio />} label="شركة تأجير/استئجار" />
              <FormControlLabel value="broker" control={<Radio />} label="وسيط تأجير" />
            </RadioGroup>
            <Button variant="contained" color="primary" onClick={handleNext}>
              استمرار
            </Button>
          </div>
        );
      case 1:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              اختر المدن الأكثر شعبية في المملكة العربية السعودية:
            </Typography>
            <Typography variant="body1" gutterBottom>
              في أي مدينة تعمل؟ هذا السؤال مطلوب.
            </Typography>
            <RadioGroup value={selectedOption} onChange={handleOptionChange}>
              <FormControlLabel value="riyadh" control={<Radio />} label="الرياض" />
              <FormControlLabel value="jeddah" control={<Radio />} label="جدة" />
              <FormControlLabel value="dammam" control={<Radio />} label="الدمام" />
              <FormControlLabel value="mecca" control={<Radio />} label="مكة المكرمة" />
              <FormControlLabel value="medina" control={<Radio />} label="المدينة المنورة" />
            </RadioGroup>
            <Button variant="contained" color="primary" onClick={handleNext}>
              استمرار
            </Button>
          </div>
        );
      case 2:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              ما هو نوع القارب الذي تريد إدراجه؟ هذا السؤال مطلوب.
            </Typography>
            <Typography variant="body1" gutterBottom>
              اختر النوع الأول الذي تخطط لإضافته.
            </Typography>
            <RadioGroup value={selectedOption} onChange={handleOptionChange}>
              <FormControlLabel value="power" control={<Radio />} label="قوة، عوامة، يخت، أو RIB" />
              <FormControlLabel value="sailboat" control={<Radio />} label="قارب شراعي أو قارب قطمران" />
              <FormControlLabel value="jetski" control={<Radio />} label="جيتسكي أو PWC" />
              <FormControlLabel value="non-powered" control={<Radio />} label="غير مزود بمحرك (مثل الكاياك، إلخ)" />
            </RadioGroup>
            <Button variant="contained" color="primary" onClick={handleNext}>
              استمرار
            </Button>
          </div>
        );
      case 3:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              كيف ستدير عملك؟ هذا السؤال مطلوب.
            </Typography>
            <Typography variant="body1" gutterBottom>
              إذا لم تكن متأكدًا، اختر الخيار الأقرب.
            </Typography>
            <RadioGroup value={selectedOption} onChange={handleOptionChange}>
              <FormControlLabel value="captain-included" control={<Radio />} label="الكابتن متضمن في السعر" />
              <FormControlLabel value="captain-arranged" control={<Radio />} label="الكابتن يتم ترتيبه بشكل منفصل" />
              <FormControlLabel value="no-captain" control={<Radio />} label="الكابتن غير متوفر" />
            </RadioGroup>
            <Button variant="contained" color="primary" onClick={handleNext}>
              استمرار
            </Button>
          </div>
        );
      case 4:
        return (
          <div className="step-content">
            <Typography variant="h6" gutterBottom>
              رائع! لقد قاربنا على الانتهاء.
            </Typography>
            <Typography variant="body1" gutterBottom>
              بناءً على إجاباتك، إليك المعلومات التي تحتاج إلى معرفتها:
            </Typography>
            <Box className="info-box">
              <Typography variant="h6" gutterBottom>
                1. تغطية التأمين
              </Typography>
              <Typography variant="body2" gutterBottom>
                لا تنس التحقق مع مزود التأمين الحالي لديك للتأكد من تغطية قاربك للاستئجار. سنرسل أيضًا معلومات من شريكنا تأمين القوارب، حتى تتمكن من طلب عرض إذا لزم الأمر.
              </Typography>
            </Box>
            <Box className="info-box">
              <Typography variant="h6" gutterBottom>
                2. اتفاقية الإيجار
              </Typography>
              <Typography variant="body2" gutterBottom>
                يجب عليك تقديم اتفاقية إيجار مكتوبة أو إلكترونية مع المستأجر لكل حجز. يجب قراءة وتوقيع هذه الاتفاقية الرسمية من قبل الطرفين قبل المغادرة.
              </Typography>
            </Box>
            <Box className="info-box">
              <Typography variant="h6" gutterBottom>
                3. الترخيص والسعة
              </Typography>
              <Typography variant="body2" gutterBottom>
                عندما يتحمل عملاؤك مسؤولية تشغيل قاربك، يجب عليك التحقق من أنهم يمتلكون دليل كفاءة صالح. يجب ألا تتجاوز السعة القصوى تلك المدرجة في إشعار الامتثال للقارب الخاص بك.
              </Typography>
            </Box>
            <Box className="info-box">
              <Typography variant="h6" gutterBottom>
                4. المدفوعات
              </Typography>
              <Typography variant="body2" gutterBottom>
                عندما يحجز عملاؤك رحلة، سيدفعون التكلفة الإجمالية مقدمًا من خلال Marisa. بمجرد تأكيد الرحلة، ستتلقى تفاصيل العملاء ويمكنك التواصل معهم مباشرة. للحفاظ على سلامتك، لا نسمح بالدفع النقدي المقدم أو تبادل معلومات الاتصال بما في ذلك البريد الإلكتروني والمواقع الإلكترونية وأرقام الهاتف.
              </Typography>
            </Box>
            <Box className="info-box">
              <Typography variant="body2" gutterBottom>
                * قد تتطلب بعض المسطحات المائية تصاريح أو لوائح إضافية. بإضافة قائمة على Marisa، فإنك توافق على أنك المسؤول الوحيد عن الامتثال لجميع اللوائح المحلية والولائية والوطنية.
              </Typography>
            </Box>
            <Typography variant="body2" gutterBottom>
              نحن ندرك أن لوائح القوارب يمكن أن تكون صعبة، ونريد أن نضمن أنك جاهز تمامًا للماء. يرجى إعلامنا إذا كنت بحاجة إلى مزيد من المعلومات قبل البدء.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleNext}>
              إنتهاء
            </Button>
          </div>
        );
      case 5:
        return (
          <div className="final-step">
            <Lottie animationData={boatAnimation} loop={true} className="lottie-animation" />
            <Typography variant="h4" gutterBottom>
              تم استلام طلبك!
            </Typography>
            <Typography variant="body1" gutterBottom>
              شكرًا لك على تقديم طلبك. سنقوم بالتواصل معك في أقرب وقت ممكن.
            </Typography>
            <Button className="custom-button" onClick={handleHomeRedirect}>
  العودة إلى الصفحة الرئيسية
</Button>

          </div>
        );
      default:
        return 'خطوة غير معروفة';
    }
  };

  return (
    <Container maxWidth="lg" className="create-listing-container">
      <Box className="stepper-container">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      
      <Box className="content-container">
        <TransitionGroup>
          <CSSTransition
            key={activeStep}
            timeout={500}
            classNames="fade"
          >
            {renderStepContent(activeStep)}
          </CSSTransition>
        </TransitionGroup>
        {activeStep < steps.length - 1 && (
          <Box className="navigation-buttons">
            <Button onClick={handleBack} disabled={activeStep === 0}>
              العودة
            </Button>
          </Box>
        )}
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleSnackbarClose} severity="warning">
          يرجى اختيار خيار للمتابعة!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateListing;
