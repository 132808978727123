import React from 'react';
import Slider from 'react-slick';
import { Box, Card, CardMedia, CardContent, Typography, Container, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import './Variety.css';

const boatTypes = [
  { image: 'https://www.tessllc.us/wp-content/uploads/2020/07/yacht-post-825x510.jpg', title: 'القارب الفاخر', description: 'استمتع برحلة فاخرة على متن هذا القارب.', rating: 4.5 },
  { image: 'https://images.unsplash.com/photo-1542351379-ec2b4cdc6f1d?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', title: 'قارب الصيد', description: 'قارب مثالي لرحلات الصيد مع الأصدقاء والعائلة.', rating: 4.0 },
  { image: 'https://plus.unsplash.com/premium_photo-1677535536180-c7c9d83f48a8?q=80&w=1633&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', title: 'القارب السريع', description: 'تجربة السرعة والمغامرة على الماء مع هذا القارب السريع.', rating: 3.5 },
];

const FullWidthContainer = styled('section')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
  boxSizing: 'border-box',
  backgroundColor: '#18504a',
  direction: 'rtl', // Set the overall direction to RTL
});

const SpecificWidthContainer = styled(Container)({
  maxWidth: '1200px', // Specific max width
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

const VarietyContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4),
}));

const VarietyCard = styled(Card)(({ theme }) => ({
  maxWidth: '350px',
  margin: '0 8px', // Adjust margin to add space between cards
  borderRadius: '10px',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const CustomButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#2a8675',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 8px',
  '&:hover': {
    backgroundColor: '#1d6b5b',
  },
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Variety = () => {
  const sliderRef = React.useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <FullWidthContainer>
      <SpecificWidthContainer>
        <Typography variant="h4" align="center" gutterBottom sx={{ paddingTop: 5, color: '#fff' }}>
          تجارب لا تنسى
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom sx={{ color: '#fff' }}>
          تأجير قوارب فريدة من نوعها في جميع أنحاء العالم
        </Typography>
        <VarietyContainer>
          <Slider ref={sliderRef} {...settings}>
            {boatTypes.map((boat, index) => (
              <VarietyCard key={index}>
                <CardMedia
                  component="img"
                  height="200"
                  image={boat.image}
                  alt={boat.title}
                />
                <CardContent className="card-content"> {/* Align text to center */}
                  <Box className="card-header">
                    <Box className="card-rating">
                      <StarIcon sx={{ color: 'orange' }} />
                      <Typography variant="body2" color="text.secondary">
                        {boat.rating}
                      </Typography>
                    </Box>
                    <Typography gutterBottom variant="h6" component="div">
                      {boat.title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" className="ellipsis">
                    {boat.description}
                  </Typography>
                </CardContent>
              </VarietyCard>
            ))}
          </Slider>
        </VarietyContainer>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CustomButton onClick={handlePrev}>
            <ArrowBackIosIcon />
          </CustomButton>
          <CustomButton onClick={handleNext}>
            <ArrowForwardIosIcon />
          </CustomButton>
        </Box>
      </SpecificWidthContainer>
    </FullWidthContainer>
  );
};

export default Variety;
