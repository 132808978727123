import React, { useState } from 'react';
import { Container, Box, IconButton, Grid, TextField, Button, Typography, Link, InputAdornment, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useAuth } from '../../services/AuthContext';
import Toast, { showToast } from '../../services/Toast';
import { useHistory } from 'react-router-dom';
import apiConfig from '../../apiConfig';
import './SignIn.css';

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading
  const { saveAuthState } = useAuth();
  const history = useHistory();

  // Handle form changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      // Send sign-in request
      const response = await axios.post(`${apiConfig.userService}/users/signin`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Save the token and user data in the AuthContext
      saveAuthState({
        token: response.data.token,
        user: response.data.user, // Store user details if provided in response
      });

      // Show success toast message
      showToast('تم تسجيل الدخول بنجاح', 'success');
      
      // Redirect to home page or dashboard
      history.push('/'); 
    } catch (error) {
      // Show error toast message
      console.error('خطأ في تسجيل الدخول:', error);
      showToast('فشل تسجيل الدخول', 'error');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Close the sign-in modal
  const handleClose = () => {
    history.push('/');
  };

  return (
    <Container sx={{ direction: 'rtl' }}>
      <Toast />
      <Box
        className="signin-box"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <IconButton className="close-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h3" mb={2} className="signin-heading" style={{ fontFamily: 'MyriadPro-Regular' }}>
          تسجيل الدخول
        </Typography>
        <Typography variant="body1" mb={4} className="signin-description" style={{ fontFamily: 'MyriadPro-Light' }}>
          يرجى تسجيل الدخول إلى حسابك للوصول إلى الميزات
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="البريد الإلكتروني"
              variant="outlined"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="animated-textfield"
              InputLabelProps={{ className: 'textfield-label', style: { direction: 'rtl' } }}
              inputProps={{ style: { direction: 'rtl', fontFamily: 'MyriadPro-Regular' } }}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#44d8a4',
                },
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#44d8a4',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#44d8a4',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="كلمة المرور"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="animated-textfield"
              InputLabelProps={{ className: 'textfield-label', style: { direction: 'rtl' } }}
              inputProps={{ style: { direction: 'rtl', fontFamily: 'MyriadPro-Regular' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#44d8a4',
                },
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#44d8a4',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#44d8a4',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Link href="/forgot-password" underline="hover">نسيت كلمة المرور؟</Link>
          </Grid>
          <Grid item xs={12}>
            {/* Show CircularProgress inside the button when loading */}
            <Button 
              variant="contained" 
              fullWidth 
              className="btn" 
              onClick={handleSubmit}
              disabled={loading} // Disable button while loading
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'تسجيل الدخول'}
            </Button>
          </Grid>
        </Grid>
        <Box
          mb={2}
          className="dont-have-an-account"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'MyriadPro-Regular'
          }}
        >
          <Typography variant="body2" sx={{ mt: 5 }}>
            ليس لديك حساب؟{' '}
          </Typography>
          <Link href="/register" underline="hover" sx={{ mt: 5, ml: 1, color: '#41be92', fontSize: '15px', fontWeight: 'bold' }}>
            إنشاء حساب
          </Link>
        </Box>
        <Box
          mb={0}
          className="dont-have-an-account"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'MyriadPro-Regular'
          }}
        >
          <Typography variant="body2">
            محمي بواسطة{' '}
            <Link href="/privacy-policy" underline="hover" sx={{ color: '#41be92' }}>
              سياسة الخصوصية
            </Link>{' '}
            و{' '}
            <Link href="/terms-of-service" underline="hover" sx={{ color: '#41be92' }}>
              شروط الخدمة
            </Link>{' '}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default SignIn;
